jQuery(document).ready(function($){

	var down = new Waypoint({
	  element: $('body'),
	  handler: function(direction) {
	  	$('.site-header').toggleClass('smaller', direction==='down')
	  	$('.site-logo').toggleClass('smaller', direction==='down')
	  },
	  offset: -100
	})
});